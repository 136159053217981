import { auth } from '@singcolor/firebase-client'
import {
  DebugGrpcClient,
  OrganizationGrpcClient,
  UsersGrpcClient,
  DiamondsGrpcClient,
  WebCoinGrpcClient,
  TsunaguGrpcClient,
  buildGrpcHeader
} from '@singcolor/grpc-client'

export const useGrpcClient = () => {
  const metadataBuilder = async () => {
    const commonHeaders = buildGrpcHeader()
    const metadata: Record<string, string> = { ...commonHeaders }

    const idToken = await auth.currentUser?.getIdToken()
    if (idToken) {
      metadata['Authorization'] = `Bearer ${idToken}`
    }

    return metadata
  }

  const debugClient = new DebugGrpcClient(metadataBuilder)
  const organizationClient = new OrganizationGrpcClient(metadataBuilder)
  const userClient = new UsersGrpcClient(metadataBuilder)
  const diamondClient = new DiamondsGrpcClient(metadataBuilder)
  const webCoinClient = new WebCoinGrpcClient(metadataBuilder)
  const tsunaguGrpcClient = new TsunaguGrpcClient(metadataBuilder)

  return { debugClient, organizationClient, userClient, diamondClient, webCoinClient, tsunaguGrpcClient }
}
