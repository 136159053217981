/** 画面遷移のパスを管理する */
export const Path = {
  authSignIn: '/auth/sign_in',
  callbackLine: '/auth/callback/line',
  root: '/',
  exchangeToCoin: '/exchange/coin',
  exchangeHome: '/exchange',
  withdraw: '/withdraw',
  diamondDetail: '/diamonds',
  coins: '/coins',
  exchangeHistories: '/exchange/histories',
  withdrawHistoryDetail: (id: string) => `/withdraw/histories/${id}`,
  bankAccounts: '/bank_accounts',
  statistic: '/statistics'
}

export const ExternalPath = {
  lineAuthURL: () => {
    const clientId = process.env.NEXT_PUBLIC_LINE_CHANNEL_ID
    const callbackUri = process.env.NEXT_PUBLIC_LINE_CALLBACK_URI
    const state = Math.random().toString(32).substring(2)
    const to = `https://access.line.me/oauth2/v2.1/authorize?client_id=${clientId}&redirect_uri=${callbackUri}&state=${state}&response_type=code&scope=openid%20email%20profile`
    return to
  },
  lp: 'https://colorsing.com',
  faq: 'https://faq.colorsing.com',
  howToConnectWithLine: 'https://faq.colorsing.com/2c5cc2fd6347499fa0bd0447910494a6',
  howToCheckInvoiceNumber: 'https://www.invoice-kohyo.nta.go.jp/about-toroku/index.html'
}
