import React, { useEffect } from 'react'
import { useRouter } from 'next/router'
import { useCallback, useState } from 'react'
import { auth } from '@singcolor/firebase-client'
import { signInWithCustomToken } from 'firebase/auth'
import { useGrpcClient } from 'src/modules/grpc/hooks'
import { Path } from '../path'
import { CreateTokenRequest } from '@singcolor/grpc-client/src/singcolor-proto/ts/debug_pb'
import { DebugGrpcClient } from '@singcolor/grpc-client'

const handleError = (error: unknown, setError?: React.Dispatch<React.SetStateAction<Error | undefined>>) => {
  let errorMessage = 'Auth Error occurred'
  if (error instanceof Error) {
    setError?.(error)
    errorMessage += ` :${error.message}`
    errorMessage
  }
  //   Toaster.error(errorMessage)
}

export const useSignIn = () => {
  const router = useRouter()
  const { userClient } = useGrpcClient()

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error>()

  const handleSignInWithLineLogin = useCallback(
    async ({ code }: { code: string }) => {
      try {
        setIsLoading(true)
        setError(undefined)
        const res = await userClient.lineLoginByCode({ code })

        await signInWithCustomToken(auth, res.customToken)

        setIsLoading(false)
        router.push(Path.root)
      } catch (error: any) {
        setIsLoading(false)
        console.log(error)
        handleError(error, setError)
      }
    },
    [userClient, router]
  )

  const handleSignInWithDebugLogin = useCallback(
    async ({ userId, loginType }: { userId: string; loginType: CreateTokenRequest.LoginType }) => {
      try {
        setIsLoading(true)
        setError(undefined)

        const client = new DebugGrpcClient(async () => {
          return {}
        })
        const res = await client.createCustomToken({ userId, loginType })
        await signInWithCustomToken(auth, res.customToken)

        setIsLoading(false)
        router.push(Path.root)
      } catch (error: any) {
        setIsLoading(false)
        console.log(error)
        handleError(error, setError)
      }
    },
    [router]
  )

  const clearError = useCallback(() => {
    setError(undefined)
  }, [])

  return { isLoading, error, handleSignInWithLineLogin, handleSignInWithDebugLogin, clearError }
}

export const useSignOut = () => {
  const router = useRouter()

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error>()

  const handleSignOut = useCallback(async () => {
    try {
      setIsLoading(true)
      await auth.signOut()
      setIsLoading(false)
      router.push(Path.authSignIn)
    } catch (error: any) {
      setError(error)
    } finally {
      setIsLoading(false)
    }
  }, [router])
  return { isLoading, error, handleSignOut }
}

export const useAuthRequired = () => {
  const router = useRouter()
  const [isInit, setIsInit] = useState(false)

  useEffect(() => {
    // theme の override 時に使用する
    const jssStyles = document.querySelector('#jss-server-side')
    if (jssStyles) {
      jssStyles.parentElement?.removeChild(jssStyles)
    }
    if (!isInit) {
      const unsubscriber = auth.onIdTokenChanged(user => {
        if (!user) {
          router.push(Path.authSignIn)
        }
        setIsInit(true)
        unsubscriber()
      })
    }
  }, [isInit, router])

  return { isInit }
}

export const useIsLoggedIn = () => {
  const [isInit, setIsInit] = useState(false)
  const [isLoggedIn, setIsLoggedIn] = useState(false)

  useEffect(() => {
    const unsubscriber = auth.onIdTokenChanged(user => {
      if (process.env.NODE_ENV !== 'production') console.log(user)
      setIsLoggedIn(!!user)
      setIsInit(true)
    })
    return () => {
      unsubscriber()
    }
  }, [])

  return { isInit, isLoggedIn }
}
